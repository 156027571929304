// TO DO: Bakcground image 
import React, { useState } from 'react'
import { useLocation } from "wouter";

function ShowMoreTeam({item}){
    const [location, setLocation] = useLocation();
    const navigate = (url) => {
        console.log(url)
        setLocation(url);
      };
    return (
        <div className='relative group'>
            <div className='w-[147px] h-[147px] sm:w-[224px] sm:h-[224px] group-hover:sm:w-[270px] group-hover:sm:h-[287px] sm:bg-secondary transition-all relative z-10'>
                <div className='sm:w-[224px] sm:h-[224px] group-hover:sm:w-[270px] group-hover:sm:h-[287px] sm:group-hover:hidden relative z-20'>
                    <img className='object-cover w-full h-full' src={item.image}></img>
                </div>
                {/* <div className='backgroundImage bg-[#F7F7F7] sm:w-[199px] sm:h-[185px] w-[120px] h-[120px] relative top-0 left-[-25px] z-0 group-hover:hidden'>
                </div>              */}
                <div style={{fontFamily: 'Poppins'}} className='sm:group-hover:block hidden text-[#fff] sm:py-[40px] sm:px-[25px] px-[10px] py-[20px] text-center'>
                    <p className='sm:text-[21px] text-[12px] text-left'>{item.name}</p>
                    <div className='mt-[20px] mb-[60px]'>
                        <p className='text-[12px] text-left'>{item.phone}</p>
                        <p className='sm:text-[12px] text-[12px] mt-[15px] text-left'>{item.email}</p>
                    </div>
             <button onClick={() => navigate(item.link)} className='underline text-center cursor-pointer'>{item.more}</button>
                </div>
            </div>
            <div className='sm:group-hover:hidden p-[10px]'>
                <p className='text-center text-[#131313] sm:text-[16px] text-[12px]'>{item.name}</p>
            </div>
        </div>
    )
}
export default ShowMoreTeam;