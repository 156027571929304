const en = {
    "team": [
        {
            "name": "PABLO ARELLANO",
            "phone": "+52 (55) 1167-3040",
            "email": "pablo.arellano@creelabogados.com",
            "image": require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg'),
            "more": "Read more",
            "link": "/partners/7",

        },
        {
            "name": "TSEN FANG-LIN",
            "phone": "+52 (55) 1167-3034",
            "email": "tsen-fang.lin@creelabogados.com",
            "image": require('../../assets/equipo/Asociados/Home/Tsen_Esc.jpg'),
            "more": "Read more",
            "link": "/associates/4",

        },
        {
            "name": "PABLO TORRES A.",
            "link": "/associates/11",
            "image": require('../../assets/equipo/Asociados/Home/PabloTorres_Esc.jpg'),
            "phone": "+52 (55) 1167-3010, ext. 123",
            "email": "pablo.torres@creelabogados.com",
            "more": "Read more"
        }
    ],
    "button": "MEET OUR TEAM"
}
export default en;