import React, {useContext} from 'react'
import Notes from '../../assets/recursos/Notes';
import { LanguageContext } from '../../context/LanguageContext'

function Card({card}){
    const { language } = useContext(LanguageContext);

    return(
        <div className='flex flex-col sm:w-[362px] h-[320px] w-[332px] shadow-md shadow-gray-500'>
            <div className='flex flex-col p-[20px] h-full'>
                <div className='flex mb-auto'>
                    <div className='w-[24px] h-[24px] mr-[15px]'>
                        <Notes />
                    </div>
                    <p style={{fontFamily: 'Poppins SemiBold'}} className='text-[#04141C] text-[20px] pb-[20px]' > {card.nombre} </p>
                </div>
                <p style={{fontFamily: 'Poppins Light'}} className='text-[#7D878F] text-[16px]'>{card.descripcion}</p>
            </div>
            <div style={{fontFamily: 'Poppins Light'}} className='flex justify-between mt-auto p-[20px] text-[#7D878F]'>
            <a href={card.url_archivo} target="_blank" className='cursor-pointer'>{language == 'es' ? 'Leer más' : 'Read more'}</a>
                {/* <a href={`/articles/${card.slug}`} className='cursor-pointer'>{language == 'es' ? 'Leer más' : 'Read more'}</a> */}
                <p>{card.date}</p>
            </div>
        </div>
    )
}

export default Card;