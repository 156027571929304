const en = {
    timeline: {
        name: "Our History",
        items: ["1936", "1950", "1955", "1972", "1977", "2000", "Today"]
    },
    subtitle: "Creel/abogados: A legacy of excellence and tradition.",
    title: "We are proud of our history and value our tradition and strong Firm’s culture built during more than five decades.",
    t1: {
        title: "1936: Luis J. Creel Luján",
        description: ["The foundations of <span style='font-family: Poppins Medium'>Creel/abogados</span> were laid by Mr. Luis J. Creel Luján, who obtained his law degree from the Universidad Nacional Autónoma de México on July 22, 1936. Mr. Creel had his initial exposure to the practice of law at the prestigious law firm of Mr. Carlos Sánchez Mejorada Domínguez. Under the supervision and guidance of his mentor, Mr. Creel centered his professional practice on representing English, Canadian and American companies that had a significant presence in the Mexican mining industry.", "During the following years, Mr. Creel moved to the city of Pachuca to serve the legal matters of Real del Monte, one of the most relevant mining groups in the history of Mexico. Upon his return to Mexico City and during the 1940s, Mr. Creel continued the representation of foreign investors and actively participated in the Mexican Bar, presiding several of its committees, publishing a variety of articles on tax matters –his preferred field of analysis- and acting as Editor of its journal “El Foro”."],
        image: [require('../../assets/historia/img_1936.jpg')],
        image_description: "Mr. Luis J. Creel Luján in his office at the 18th Floor of the Banco Internacional Building.",   
    },
    t2: {
        title: "1950",
        description: ["In the early 1950's, Mr. Creel established his independent practice in a modest office located in Avenida Juárez, within the central quarter of Mexico City, focusing his services in mining, tax and foreign investment law."],
        image: [require('../../assets/historia/img_1950-1.jpg'), require('../../assets/historia/img_1950-2.jpg'),]
    },
    t3: {
        title: "1955 - 1966",
        description: ["Mr. Creel maintained the original venue of his practice until 1955, when he moved to Paseo de la Reforma, from where he continued advising foreign companies in their Mexican ventures for over a decade, with the unconditional support of his associate Mr. Alberto Hoyos T.", "In 1966, following an unfortunate fire incident in his offices which damaged part of the premises, Mr. Creel again relocated his practice to the headquarters of Banco Internacional, in Paseo de la Reforma 156."],
        image: [require('../../assets/historia/img_1955-1.jpg'), require('../../assets/historia/img_1955-2.jpg')]
    },
    t4: {
        title: "1972: Creel/abogados",
        description: ["Mr. Creel’s practice gained strength with the addition of his son Luis J. Creel Carrera. Together they founded <span style='font-family: Poppins Medium'>Creel/abogados</span> in 1972, and three years later established the Firm's offices Desc building, situated in the then modern environs of Bosques de las Lomas."],
        image: [require('../../assets/historia/img_1972-1.jpg'), require('../../assets/historia/img_1972-2.jpg')]
    },
    t5: {
        title: "1977 - 1999",
        description: ["Mr. Creel passed away in 1977 and his son Luis took the helm of the family firm and initiated its institutionalization, transforming <span style='font-family: Poppins Medium'>Creel/abogados</span> into a prominent corporate law firm.", "Luis promoted the growth of the partnership and the broadening of the services offerings incorporating the Banking and Securities, as well the Real Estate Practice Areas. Under his leadership, the Firm achieved top-tier status among corporate law firms in Mexico. Luis continued to lead the Firm until the end of the 90s."],
        image: [require('../../assets/historia/img_1977-1.jpg'), require('../../assets/historia/img_1977-2.jpg')]
    },
    t6: {
        title: "2000 - 2013",
        description: ["In 2000 Carlos Creel Carrera was appointed Managing Partner. Under his guidance during the following years our Partners developed their professional practices in the Firm's core areas, formed a strong team of lawyers who shared their values and vision, and consolidated their local and international network strategy by establishing close professional relationships with reputable law firms and advisors in the Americas and Europe. <br> Since 2013, Carlos acts as Senior Partner of <span style='font-family: Poppins Medium; font-size: 16px'>Creel/abogados</span>."],
        image: [require('../../assets/historia/edificio-creel.jpg'), require('../../assets/historia/NH-00-13_escritorio.jpg')]
    },
    t7: {
        title: "2015 - today",
        description: ["From the second decade of this 21st century, <span style='font-family: Poppins Medium'>Creel/abogados</span> has maintained its dedicated attention on representing Mexican and international business and financial groups, investment funds and real estate developers, both domestic and foreign; expanding its offer of specialized legal services to focus on new Practice Areas created with the advancement of financial and commercial technology, as well as by innovative business models.", "Recognizing the dynamic and profound transformation of our environment, we have strengthened our Labor, Natural Resources, Compliance and ASG practices through the incorporation of talented partners, counsels and associates."],
        image: [require('../../assets/historia/NH-2015Retrato_escritorio.jpg')],
        image_description: 'Portrait of Luis J. Creel Luján presiding our Boardroom at the 29th Floor of the Arcos office complex.'
    },
    t8: {
        title: "Today",
        description: ["<p style='text-align:center'>Today, established at the current venue of <span style='font-family: Poppins Medium'>Creel/abogados</span>, we continue providing corporate legal advice, with the same quality that has defined us throughout our history. We honor the legacy of excellence and professionalism laid down by our original founders, and maintain the spirit and culture that have distinguished us in the Mexican legal market for several decades.</p>", "<p style='text-align:center'>Our sights are set firmly on staying in the vanguard of the legal industry as it evolves to meet the demands of a more complex, faster changing and more connected world.</p>"],
        image: [require('../../assets/historia/hoy.jpg')],
    }
}

export default en;