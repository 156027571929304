const esTeamLang = {
    "socios": [
        {
            "name": "Carlos Creel C.",
            "link": "/partners/1",
            "image": require('../../assets/equipo/Socios/Home/CarlosCreel_Esc.jpg')
        },
        {
            "name": "Javier Portas L.",
            "link": "/partners/2",
            "image": require('../../assets/equipo/Socios/Home/JavierPortas_Esc.jpg')
        },
        {
            "name": "Gustavo Struck Creel",
            "link": "/partners/3",
            "image": require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg')
        },
        {
            "name": "César Morales G.",
            "link": "/partners/5",
            "image": require('../../assets/equipo/Socios/Home/CesarMorales_Esc.jpg')
        },
        {
            "name": "Pablo Arellano Q.",
            "link": "/partners/7",
            "image": require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg')
        },
        {
            "name": "Guillermo Rocha L.",
            "link": "/partners/9",
            "image":require('../../assets/equipo/Socios/Home/Guillermo_Home.jpg')
        },
        {
            "name": "Ernesto Saldate A.",
            "link": "/partners/8",
            "image": require('../../assets/equipo/Socios/Home/ErnestoSaldate_Esc.jpg')
        },
        {
            "name": "Home",
            "link": "/"
        }
    ],
    "consejeros": [
        {
            "name": "Luis J. Creel C.",
            "link": "/advisers/1",
            "legend": "Honorary Counsel",
            "image": require('../../assets/equipo/Consejeros/Home/LuisJCreel_Esc.jpg')
        },
        {
            "name": "Jorge Torres B.",
            "legend": "Senior Counsel",
            "link": "/advisers/2",
            "image": require('../../assets/equipo/Consejeros/Home/JorgeTorresB_Esc.jpg')
        },
        {
            "name": "Alejandro Creel Y.",
            "legend": "Counsel",
            "link": "/advisers/3",
            "image": require('../../assets/equipo/Consejeros/Home/Alejandro_Esc.jpg')
        },
        {
            "name": "Alejandro Gorozpe Z.",
            "legend": "Counsel",
            "link": "/advisers/4",
            "image": require('../../assets/equipo/Consejeros/Home/AlejandroGorozpe_Home.jpg')
        },
        {
            "name": "Home",
            "link": "/"
        }
    ],
    "asociados": [
        {
            "name": "Diego Corvera H.",
            "link": "/associates/1",
            "image": require('../../assets/equipo/Asociados/Home/DiegoCorvera_Home.jpg')
        },
        {
            "name": "Tsen Fang-Lin",
            "link": "/associates/4",
            "image": require('../../assets/equipo/Asociados/Home/Tsen_Esc.jpg')
        },
        {
            "name": "Sandra López Ch.",
            "link": "/associates/6",
            "image": require('../../assets/equipo/Asociados/Home/SandraLopez_Esc.jpg')

        },
        {
            "name": "Fernanda Melgar G.",
            "link": "/associates/8",
            "image": require('../../assets/equipo/Asociados/Home/MariaFernandaMelgar_Esc.jpg')
        },
        {
            "name": "Moisés Prochovnik A.",
            "link": "/associates/9",
            "image": require('../../assets/equipo/Asociados/Home/Moises_Esc.jpg')
        },
        {
            "name": "Jonathan Segura M.",
            "link": "/associates/10",
            "image": require('../../assets/equipo/Asociados/Home/JonathanSegura_Esc.jpg')
        },
        {
            "name": "Pablo Torres A.",
            "link": "/associates/11",
            "image": require('../../assets/equipo/Asociados/Home/PabloTorres_Esc.jpg')
        }     
    ],
    "pasantes": [
        {
            "name": "Viviana Ballesteros M.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 125",
            "email": "viviana.ballesteros@creelabogados.com",
            "contact": "Contact",
            vcard: require('../../assets/vcards/Viviana_Ballesteros.vcf')
        },
        {
            "name": "Diego Casas C.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 129",
            "email": "diego.casas@creelabogados.com",
            "contact": "Contact",
            vcard: require('../../assets/vcards/Diego_Casas.vcf')
        },
        {
            "name": "Regina Rea S.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 112",
            "email": "regina.rea@creelabogados.com",
            "contact": "Contact",
            vcard: require('../../assets/vcards/Regina_Rea_S.vcf')
        },
        {
            "name": "Pablo Sánchez F.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 128",
            "email": "pablo.sanchez@creelabogados.com",
            "contact": "Contact",
            vcard: require('../../assets/vcards/Pablo_Sanchez.vcf')
        },
        {
            "name": "Carolina Trujillo B.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 130",
            "email": "carolina.trujillo@creelabogados.com",
            "contact": "Contact",
            vcard: require('../../assets/vcards/Carolina_Trujillo_B.vcf')
        },
        {
            "name": "Tania Zapan M.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 122",
            "email": "tania.zapan@creelabogados.com",
            "contact": "Contact",
            vcard: require('../../assets/vcards/Tania_Zapan_M.vcf')
        }
    ]
}

export default esTeamLang